import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SelectDate from '../components/select/selectDate'
import { headerStyle, style, productStyle } from './styles'
import { toCurrency, formatDate } from './common'
import './styles/style.css'
import PromoByProductPopup from '../components/product/PromoByProductPopup'
import deleteIcon from '../assets/icons/delete.svg'
import PopupDetailProduct from '../pages/Customer/CustomerVisitSellingDetailing/popupDetailProduct'
import { ICFilter } from '../assets/icons'
import { ButtonCustom } from '../components/button'
import { BaseTextarea } from '../components/input'
import { MEMBER_TYPE_CODE } from '../constant'
import { id } from 'date-fns/locale'

const useFieldRowOne = (props) => {
    const auth = useSelector(state => state.auth.user)
    const history = useNavigate();
    const [stockHistoryCurrentStock, setStockHistoryCurrentStock] = useState([]);
    const {
        checkboxProps,
        handleChangeDate,
        handleSelectedData2,
        selectedData,
        handleSelectedAllRows,
        handleSelectRow,
        handleChangeQty,
        handleChangePresentationMaterial,
        handleAddProduct,
        handleDelProduct,
        handleChangeReasonNoOrder,
        handleChangeNotesNoOrder,
        handleChange,
        handleChangeHistory,
        handlePromo,
        PresentationddData,
        ReasonforNoOrderVisiblity,
        detail,
        setParentState,
    } = props

    const updateStock = (index, value) => {
        const updatedData = [...stockHistoryCurrentStock];
        updatedData[index] = value;
        setStockHistoryCurrentStock(updatedData);
        setParentState(prevState => {
            return {
                ...prevState,
                stockHistoryCurrentStock: updatedData
            };
        });
    };

    return {
        reschedule: [
            {
                Header: (item) => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.all === 1}
                            indeterminate={selectedData.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true}
                            onChange={() => handleSelectRow(original[checkboxProps.selectorKey])}
                        />
                    )
                }
            },
            {
                Header: 'Customer Code',
                accessor: 'outlet.outletCode',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Customer Name',
                accessor: 'outlet.outletName',
                minWidth: 140,
                headerStyle,
                className: '',
                sortable: false,
            },
            {
                Header: 'Customer Address',
                accessor: 'outlet.outletAddress',
                minWidth: 280,
                headerStyle,
                className: '',
                style: {
                    textTransform: 'capitalize'
                },
                sortable: false
            },
            {
                Header: 'Re - Schedule',
                accessor: 'date',
                sortable: false,
                headerStyle,
                className: '',
                Cell: ({ row, original }) => {
                    return (
                        <SelectDate
                            onChange={(newDate) =>
                                handleChangeDate(newDate, row._index, original.date)
                            }
                            value={original.date} format='d LLLL y'
                        />
                    )
                }
            }
        ],
        visitPlanTeam: [
            {
                Header: 'Sales ID',
                accessor: 'memberNik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales Name',
                accessor: 'memberFullname',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Customer Name',
                accessor: 'outlet.outletName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Customer Address',
                accessor: 'outlet.outletAddress',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Category',
                accessor: 'visitCategoryType',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Date',
                accessor: 'date',
                sortable: false,
                headerStyle,
                style,
                // Cell: ({ row }) => format(parseISO(row.values.date), 'dd/MM/yyyy')
                Cell: ({ row }) => formatDate(row.date, 'dd/MM/yyyy')
            },
            {
                Header: 'Status',
                accessor: 'statusApprovalRaw',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row, original }) => (
                    <span className={`text-black ${original.statusApproval === 2 ? "text-green-500" : "text-red-500"} `}>{row.statusApprovalRaw}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'visitId',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row, original }) => (
                    <ButtonCustom onClick={() => history(`${original.statusApproval === 2 ? `/visit-plan-team/approved-detail/${row.visitId}` : `/visit-plan-team/rejected-detail/${row.visitId}`}`)}>
                        Details
                    </ButtonCustom>
                )
            }
        ],
        visitPlanTeamRequest: [
            {
                Header: (item) => {
                    return (
                        <input
                            type='checkbox'
                            disabled={auth.memberTypeCode.toLowerCase() === MEMBER_TYPE_CODE.FSS ? false : true}
                            checked={selectedData.all === 1}
                            indeterminate={selectedData.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    return (
                        <input
                            type='checkbox'
                            disabled={original.isCanApprove ? false : true}
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true}
                            onChange={() => handleSelectRow(original[checkboxProps.selectorKey])}
                        />
                    )
                }
            },
            {
                Header: 'Sales ID',
                accessor: 'memberNik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales Name',
                accessor: 'memberFullname',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Customer Name',
                accessor: 'outlet.outletName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Customer Address',
                accessor: 'outlet.outletAddress',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Category',
                accessor: 'visitCategoryType',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Date',
                accessor: 'date',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row, original }) => (
                    original.statusApproval === 1 ? formatDate(row.date, 'dd/MM/yyyy') : formatDate(original.visitReschedule.requestDate, 'dd/MM/yyyy')
                )
            },
            {
                Header: 'Status',
                accessor: 'statusApprovalRaw',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row, original }) => (
                    <span className={`text-black ${original.statusApproval === 1 ? "text-blue-500" : ""} `}>{row.statusApprovalRaw}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'visitId',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row }) => (
                    <ButtonCustom onClick={() => history(`/visit-plan-team/request-detail/${row.visitId}`)}>
                        Details
                    </ButtonCustom>
                )
            }
        ],
        collection: [
            {
                Header: 'DIH Number',
                accessor: 'dihNumber',
                sortable: false,
                headerStyle,
                style,
                className: ''
            },
            {
                Header: 'Invoice No',
                accessor: 'billingDocument',
                sortable: true,
                headerStyle,
                style,
                Cell: ({ row, original }) => {
                    return (
                        <>
                            <input
                                className='mr-2'
                                type='checkbox'
                                checked={original.isCollected === 1 ? true : ''}
                                onChange={(e) => handleSelectedData2(e, row._index)}
                            />
                            {original.billingDocument}
                        </>
                    )
                }
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                sortable: false,
                headerStyle,
                style,
                className: '',
                Cell: ({ original }) => `Rp ${toCurrency(original.amount)}`
            },
            {
                Header: 'Arrears',
                accessor: 'arrears',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Due Date',
                accessor: 'dateDih',
                headerStyle,
                style,
                sortable: false,
                // Cell: ({ original }) => formatDate(original.dateDih, 'd LLLL y')
                Cell: ({ original }) => (original.netDueDate)
            },
            {
                Header: 'Payment Schedule',
                accessor: 'paymentSchedule',
                headerStyle,
                sortable: false,
                Cell: ({ row, original }) => {
                    return (
                        <SelectDate
                            onChange={(newDate) =>
                                handleChangeDate(newDate, row._index)
                            }
                            // value={
                            //   original.paymentSchedule !== '' 
                            //     ? original.paymentSchedule
                            //     : new Date()
                            // }
                            value={original.paymentSchedule}
                            format='d LLLL y'
                        />
                    )
                }
            },
        ],
        infoPaidCollection: [
            {
                Header: 'Invoice No',
                accessor: 'invoiceNo',
                sortable: false,
                headerStyle,
                className: 'column-event'
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                sortable: false,
                headerStyle,
                className: '',
                Cell: ({ original }) => `Rp ${toCurrency(original.amount)}`
            }
        ],
        infoCSTFocus: [
            {
                Header: 'Product',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                className: ''
            },
            {
                Header: 'Stock',
                accessor: 'productQty',
                sortable: false,
                headerStyle,
                className: ''
            }
        ],
        infoSellingDetailing: [
            {
                Header: 'Product',
                accessor: 'productName',
                sortable: false,
                headerStyle
            },
        ],
        infoservicesDetails: [
            {
                Header: 'Services Name',
                accessor: 'servicesName',
                sortable: false,
                headerStyle,
                style,
            },
        ],

        infoavrAvailablity: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Listed',
                accessor: 'listed',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Shelf Stock',
                accessor: 'shelfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Out of Stock',
                accessor: 'outOfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        infoavrVisibility: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location',
                accessor: 'location',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Shelf Space',
                accessor: 'layer',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Share of Space',
                accessor: 'shelfSpace',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'No of Product Facings',
                accessor: 'noOfProductFacings',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'With Contract',
                accessor: 'withContract',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Unit of Measurement',
                accessor: 'unitOfMeasurement',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Photo Upload',
                accessor: 'photoUpload',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        row.photoUpload.length > 0 && row.photoUpload.map((image, index) => (<a className="block" rel="noreferrer" key={'uploadData_' + index} href={image} target="_blank">{image}</a>))
                    )
                },
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        infoavrRecommendation: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Type',
                accessor: 'type',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'SubType',
                accessor: 'typeSub',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'With Contract',
                accessor: 'withContract',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Person in Charge',
                accessor: 'personInCharge',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        productFocus: [
            {
                Header: 'Product Code',
                accessor: 'materialCode',
                headerStyle,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: true,
            },
            {
                Header: 'Presentation',
                accessor: 'promoName',
                sortable: false,
                headerStyle,
                width: 150,
                Cell: ({ original, row }) => {
                    return <PromoByProductPopup
                        id={original?.productId}
                        materialCode={original?.materialCode}
                        type="SellingDetailing"
                        promoId=""
                        promo="View Promo"
                        onClick={handlePromo}
                        category="productFocus"
                        index={row._index} />
                },
            },
            // Add Presentation Materials field beside promo (checkbox)
            {
                Header: 'Presentation Materials',
                accessor: 'presentationMaterial',
                sortable: false,
                headerStyle,
                width: 200,
                Cell: ({ original, row }) => {
                    let isPM = original?.isPresentation === true ? 'bg-green-500' : 'bg-line'
                    return (
                        <div className='flex justify-center'>
                            <div className='flex justify-center' onClick={() => handleChangePresentationMaterial('productFocus', row._index, original?.isPresentation)}>
                                <div className={`mr-2 w-4 h-4 rounded-sm cursor-pointer ${isPM}`} />
                            </div>
                        </div>
                    )
                },
            },
            ...(ReasonforNoOrderVisiblity === "Y" ? [
                {
                    Header: 'Reason for No Order',
                    accessor: 'Reason for No Order',
                    headerStyle,
                    sortable: true,
                    width: 200,
                    Cell: ({ original, row }) => {
                        // console.log(original,PresentationddData, "original123")
                        return (
                            <div className='flex justify-center'>
                                <select className='product_select' defaultValue={original?.reasonNoOrderName !== "" ? original?.reasonNoOrderName : null} onChange={(e) => handleChange(e, row._index)}>
                                    <option value="" disabled selected>Select</option>
                                    {PresentationddData?.map((item, i) => (
                                        <option key={item?.code} value={item.name}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        );
                    },
                },
            ] : [])
        ],
        productNew: [
            {
                Header: 'Product Code',
                accessor: 'product.materialCodeM',
                headerStyle,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Product Name',
                accessor: 'product.productName',
                sortable: false,
                headerStyle,
                width: 'auto',
                Cell: ({ original }) => {
                    return (
                        <PopupDetailProduct original={original} />
                    )
                },
            },
            {
                Header: 'Presentation',
                accessor: 'promoName',
                sortable: false,
                headerStyle,
                width: 150,
                Cell: ({ original, row }) => {
                    return <PromoByProductPopup
                        id={original.product.productId}
                        materialCode={original.product.materialCodeM}
                        type="SellingDetailing"
                        promoId={original.promoid !== '' ? original.promoid : ""}
                        promo={original.promoName !== '' ? original.promoName : "View Promo"}
                        onClick={handlePromo}
                        category="productNew"
                        index={row._index} />
                },
            },
            // Add Presentation Materials field beside promo (checkbox)
            {
                Header: 'Presentation Materials',
                accessor: 'product.presentationMaterial',
                sortable: false,
                headerStyle,
                width: 200,
                Cell: ({ original, row }) => {
                    let isPM = original.product?.presentationMaterial === true ? 'bg-green-500' : 'bg-line'
                    return (
                        <div className='flex justify-center'>
                            <div className='flex justify-center' onClick={() => handleChangePresentationMaterial('productNew', row._index, original.product?.presentationMaterial)}>
                                <div className={`mr-2 w-4 h-4 rounded-sm cursor-pointer ${isPM}`} />
                            </div>
                        </div>
                    )
                },
            },
        ],
        productSuggestion: [
            {
                Header: 'Product Code',
                accessor: 'product.materialCodeM',
                headerStyle,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                sortable: true,
                width: 'auto',
                Cell: ({ original }) => {
                    return (
                        <PopupDetailProduct type='suggestion' original={original} />
                    )
                },
            },
            {
                Header: 'Presentation',
                accessor: 'promoName',
                sortable: false,
                headerStyle,
                width: 150,
                Cell: ({ original, row }) => {
                    return <PromoByProductPopup
                        id={original.product.productId}
                        materialCode={original.product.materialCodeM}
                        type="SellingDetailing"
                        promoId={original.promoid !== '' ? original.promoid : ""}
                        promo={original.promoName !== '' ? original.promoName : "View Promo"}
                        onClick={handlePromo}
                        category="productSuggestion"
                        index={row._index} />
                },
            },
            // Add Presentation Materials field beside promo (checkbox)
            {
                Header: 'Presentation Materials',
                accessor: 'product.presentationMaterial',
                sortable: false,
                headerStyle,
                width: 200,
                Cell: ({ original, row }) => {
                    let isPM = original.product?.presentationMaterial === true ? 'bg-green-500' : 'bg-line'
                    return (
                        <div className='flex justify-center'>
                            <div className='flex justify-center' onClick={() => handleChangePresentationMaterial('productSuggestion', row._index, original.product?.presentationMaterial)}>
                                <div className={`mr-2 w-4 h-4 rounded-sm cursor-pointer ${isPM}`} />
                            </div>
                        </div>
                    )
                },
            },
        ],
        productHistory: [
            {
                Header: 'Product Code',
                accessor: 'materialCode',
                headerStyle,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: true,
            },
            {
                Header: 'Presentation',
                accessor: 'promoName',
                sortable: false,
                headerStyle,
                width: 150,
                Cell: ({ original, row }) => {
                    return <PromoByProductPopup
                        id={original?.productId}
                        materialCode={original?.materialCode}
                        type="SellingDetailing"
                        promoId=""
                        promo="View Promo"
                        onClick={handlePromo}
                        category="productFocus"
                        index={row._index} />
                },
            },
            // Add Presentation Materials field beside promo (checkbox)
            {
                Header: 'Presentation Materials',
                accessor: 'isPresentation',
                sortable: false,
                headerStyle,
                width: 200,
                Cell: ({ original, row }) => {
                    let isPM = original?.isPresentation === true ? 'bg-green-500' : 'bg-line'
                    return (
                        <div className='flex justify-center'>
                            <div className='flex justify-center' onClick={() => handleChangePresentationMaterial('productHistory', row._index, original?.isPresentation)}>
                                <div className={`mr-2 w-4 h-4 rounded-sm cursor-pointer ${isPM}`} />
                            </div>
                        </div>
                    )
                },
            },
            ...(ReasonforNoOrderVisiblity === "Y" ? [
                {
                    Header: 'Reason for No Order',
                    accessor: 'reasonNoOrderName',
                    headerStyle,
                    sortable: true,
                    width: 200,
                    Cell: ({ original, row }) => {
                        // console.log(original,PresentationddData, "original123")
                        return (
                            <div className='flex justify-center'>
                                <select className='product_select' defaultValue={original?.reasonNoOrderName !== "" ? original?.reasonNoOrderName : null} onChange={(e) => handleChangeHistory(e, row._index)}>
                                    <option value="" disabled selected>Select</option>
                                    {PresentationddData?.map((item, i) => (
                                        <option key={item?.code} value={item.name}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        );
                    },
                },
            ] : [])
            // {
            //     Header: 'Latest Stock',
            //     accessor: row => `${row?.lastStock ? row?.lastStock : ''} || ${row?.lastStockDate ? row?.lastStockDate : ''}`,
            //     id: 'latestStock',
            //     headerStyle,
            //     sortable: true,
            //     width: 150,
            //     Cell: ({ value }) => {
            //         const [lastStock, lastStockDate] = value.split('||');
            //         return (
            //             <div className='text-sm'> {/* Add text-sm class */}
            //                 <div>{lastStock}</div>
            //                 <div>{lastStockDate}</div>
            //             </div>
            //         );
            //     }
            // },
            // {
            //     Header: 'Last Order',
            //     accessor: row => `${row?.lastOrder ? row?.lastOrder : ''} || ${row?.lastOrderDate ? row?.lastOrderDate : ''}`,
            //     id: 'lastOrder',
            //     sortable: false,
            //     headerStyle,
            //     width: 150,
            //     Cell: ({ value }) => {
            //         const [lastOrder, lastOrderDate] = value.split('||');
            //         return (
            //             <div className='text-sm'> {/* Add text-sm class */}
            //                 <div>{lastOrder}</div>
            //                 <div>{lastOrderDate}</div>
            //             </div>
            //         );
            //     }
            // },
            // // Add Presentation Materials field beside promo (checkbox)
            // {
            //     Header: 'Current Stock',
            //     sortable: false,
            //     headerStyle,
            //     width: 150,
            //     accessor: row => `${row?.inputedQty ? row.inputedQty : row?.productQty }`,
            //     id: 'currentStock',
            //     Cell: ({ original, row }) => {
            //         const handleStockChange = (e) => {
            //             const value = e.target.value;
            //             if (/^\d{0,6}$/.test(value)) {
            //                 // Assuming updateStock is a function to update the stock value
            //                 updateStock(row._index, value);
            //             }
            //         };
            
            //         const accessorValue = original?.inputedQty ? original.inputedQty : original?.productQty;
            //         console.log('accessorValue', accessorValue);
            //         const displayValue = parseInt(accessorValue) === 0 ? '' : accessorValue;
            
            //         return (
            //             <div className='flex justify-center'>
            //                 <input
            //                     type='text'
            //                     value={stockHistoryCurrentStock[row._index] ? stockHistoryCurrentStock[row._index] : displayValue}
            //                     onChange={handleStockChange}
            //                     className='ml-2 w-20 text-center'
            //                     maxLength={6}
            //                 />
            //             </div>
            //         );
            //     },
            // },
            // {
            //     Header: 'Stock info',
            //     accessor: 'Calculate stock detail',
            //     sortable: false,
            //     headerStyle,
            //     width: 'auto',
            //     Cell: ({ original }) => {
            //         let txt = 'No Information';
            //         try {
            //             const itemHistoryData = original; // Assuming itemHistoryData is part of the product object
            //             const lastOrderDate = itemHistoryData.lastOrderDate;
            //             const lastStockDate = itemHistoryData.lastStockDate;
            //             const lastOrder = itemHistoryData.lastOrder ?? 0;
            //             const lastStock = itemHistoryData.lastStock ?? 0;
            //             const qty = itemHistoryData.productQty ?? 0;
            
            //             const parseOrderDate = new Date(lastOrderDate);
            //             const parseStockDate = new Date(lastStockDate);
            //             const now = new Date();
            
            //             const totOrderDay = Math.floor((now - parseOrderDate) / (1000 * 60 * 60 * 24));
            //             const totStockDay = Math.floor((now - parseStockDate) / (1000 * 60 * 60 * 24));
            
            //             if (parseOrderDate > parseStockDate) {
            //                 txt = `${(lastOrder + lastStock) - qty} Stock Left, in ${totOrderDay} Days`;
            //             }
            
            //             if (parseOrderDate < parseStockDate) {
            //                 txt = `${(lastStock - qty)} Stock Left, in ${totStockDay} Days`;
            //             }
            //         } catch (ex) {
            //             console.log('stock info', ex)
            //             txt = 'No Information';
            //         }
            
            //         return <div className='text-sm'>{txt}</div>; // Add text-sm class
            //     }
            // },
        ],
        productNoOrderFocus: [
            {
                Header: 'Product Name',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                style,
                className: '',
                Cell: ({ original }) => {
                    return <p>{original.product.productName}</p>
                }
            },
            {
                Header: 'Price',
                accessor: 'price',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <span className='font-semibold'>
                            {`Rp ${toCurrency(original.isJknPrice === true ? (original.isHasIJKNPrice === true && 0) : original.product.hnaPrice)}`}
                        </span>
                    )
                }
            },
            {
                Header: 'Reason No Order',
                accessor: 'reason',
                headerStyle,
                style,
                minWidth: 140,
                sortable: false,
                Cell: ({ original, row }) => {
                    return (
                        <select
                            className="w-2/3 p-3 mt-1 rounded-md bg-line focus:outline-none"
                            value={original.reasonNoOrderId}
                            onChange={(e) => handleChangeReasonNoOrder('productFocus', e, row._index)}
                        >
                            <option value='' disabled>Select Reason</option>
                            {original.reason.map(item => (
                                <option value={item.reasonNoOrderId} key={item.reasonNoOrderId}>{item.reasonNoOrderName}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original, row }) => {
                    return (
                        <BaseTextarea
                            rows="1"
                            placeholder="Notes"
                            value={original.notes}
                            onChange={(e) => handleChangeNotesNoOrder('productFocus', e.target.value, row._index)}
                        >
                        </BaseTextarea>)
                }
            }
        ],
        productNoOrderNewProduct: [
            {
                Header: 'Product Name',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                style,
                className: '',
                Cell: ({ original }) => {
                    return <p>{original.product.productName}</p>
                }
            },
            {
                Header: 'Price',
                accessor: 'price',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <span className='font-semibold'>
                            {`Rp ${toCurrency(original.isJknPrice === true ? (original.isHasIJKNPrice === true && 0) : original.product.hnaPrice)}`}
                        </span>
                    )
                }
            },
            {
                Header: 'Reason No Order',
                accessor: 'reason',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original, row }) => {
                    return (
                        <select
                            className="w-2/3 p-3 mt-1 rounded-md bg-line focus:outline-none"
                            value={original.reasonNoOrderId}
                            onChange={(e) => handleChangeReasonNoOrder('productNew', e, row._index)}
                        >
                            <option value='' disabled>Select Reason</option>
                            {original.reason.map(item => (
                                <option value={item.reasonNoOrderId} key={item.reasonNoOrderId}>{item.reasonNoOrderName}</option>
                            ))}
                        </select>
                    )
                }
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original, row }) => {
                    return (
                        <BaseTextarea rows="1"
                            placeholder="Notes"
                            value={original.notes}
                            onChange={(e) => handleChangeNotesNoOrder('productNew', e.target.value, row._index)}
                        >
                        </BaseTextarea>)
                }
            }
        ],
        productAdditional: [
            {
                Header: 'Brand Name',
                accessor: 'productGroupDesc',
                sortable: false,
                headerStyle,
                style,
                className: ''
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Stock',
                accessor: 'branchStock.stockAvailable',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Add Product',
                accessor: 'productId',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ row, original }) => {
                    if (original.isExist === 1) {
                        return (<div type="button" className="w-32 py-1 mx-auto text-sm button-primary rounded-full" > Already Added</div>)
                    } else {
                        return (<button type="button" className="w-16 py-1 mx-auto text-sm text-white bg-gray-500 rounded-full focus:outline-none hover:shadow-lg" onClick={() => handleAddProduct({ ...original }, row._index)} >+ Add</button>)
                    }
                }
            },
        ],
        productAdd: [
            {
                Header: 'Product Code',
                accessor: 'materialCode',
                sortable: false,
                headerStyle,
                style,
                width: 150,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                style,
                width: 200,
            },
            {
                Header: 'Promo',
                accessor: 'promoName',
                sortable: false,
                headerStyle,
                style,
                width: 150,
                Cell: ({ original, row }) => {
                    return <PromoByProductPopup
                        id={original.productId}
                        materialCode={original.materialCode}
                        type="SellingDetailing"
                        promoId={original.promoid !== '' ? original.promoid : ""}
                        promo={original.promoName !== '' ? original.promoName : "View Promo"}
                        onClick={handlePromo}
                        category="productAdditional"
                        index={row._index} />
                },
            },
            {
                Header: 'Price',
                accessor: 'hnaPrice',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `Rp ${toCurrency(original.hnaPrice)}`,
            },
            {
                Header: 'Stock',
                accessor: 'stockAvailable',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Order Quantity',
                accessor: 'qtyProduct',
                headerStyle,
                style,
                sortable: false,
                width: 150,
                Cell: ({ original, row }) => {
                    return (
                        <input
                            type='number'
                            placeholder='0'
                            min={0}
                            max={original.stockAvailable}
                            className='w-full py-1 mx-auto text-sm text-center rounded-full bg-line focus:outline-none'
                            value={original.qtyProduct}
                            required={true}
                            onChange={(e) => handleChangeQty('productAdd', e.target.value, row._index)}
                        />
                    )
                },
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'SubTotal Discount',
                accessor: 'subtotalDiscount',
                headerStyle,
                style,
                sortable: false,
                width: 200,
                Cell: ({ original }) => {
                    return `Rp ${toCurrency(original.subtotalDiscount)}`
                },
                Footer: (props) => {
                    let total = props.data.reduce((a, v) => a + parseInt(v.subtotalDiscount), 0)
                    return (
                        <p className="text-center">{`Rp ${toCurrency(total === 0 ? 0 : total)}`}</p>
                    )
                },
            },
            {
                Header: 'SubTotal',
                accessor: 'subtotal',
                headerStyle,
                style,
                sortable: false,
                width: 150,
                Cell: ({ original }) => {
                    return `Rp ${toCurrency(original.subtotal)}`
                },
                Footer: (props) => {
                    let firstTotal = props.data.reduce((a, v) => a + parseInt(v.qtyProduct), 0)
                    let total = props.data.reduce((a, v) => a + parseInt(v.subtotal), 0)
                    return (
                        <p className="text-center">{`Rp ${toCurrency(firstTotal === 0 ? firstTotal : total)}`}</p>
                    )
                },
            },
            {
                Header: 'Action',
                accessor: 'subtotal',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ row, original }) => {
                    return (
                        <button className="flex justify-center w-12 py-1 mx-auto text-sm rounded-full button-foriegn"
                        // style={{ backgroundColor: "#ED2E7E" }}
                        ><img src={deleteIcon} className="flex justify-center" alt="icon" onClick={() => handleDelProduct(row._index)} /></button>
                    )
                },
            },
        ],
        paidCollection: [
            {
                Header: 'Invoice No',
                accessor: 'billingDocument',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `Rp ${toCurrency(original.amount)}`,
            },
        ],
        customerStockRecord: [
            {
                Header: 'Product',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Stock',
                accessor: 'productQty',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Status',
                accessor: 'stockType',
                headerStyle,
                style,
                sortable: false,
            },
        ],
        sellingAndDetailing: [
            {
                Header: 'Product',
                accessor: 'productName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Reason for No Order',
                accessor: 'reasonNoOrderName',
                sortable: false,
                headerStyle,
                style,
            },
            // {
            //     Header: 'Price',
            //     accessor: 'productPrice',
            //     headerStyle,
            //     style,
            //     sortable: false,
            //     Cell: ({ original }) => `Rp ${toCurrency(original.productPrice)}`,
            //     Footer: (props) => {
            //         return (
            //             <span>Sub Total</span>
            //         )
            //     },
            // },
            // {
            //     Header: 'Quantity',
            //     accessor: 'productQty',
            //     sortable: false,
            //     headerStyle,
            //     style,
            //     Footer: (props) => {
            //         let price = props.data.reduce((a, v) => a + parseInt(v.productPrice), 0)
            //         return (
            //             <p>{`Rp ${toCurrency(price === 0 ? 0 : price)}`}</p>
            //         )
            //     },
            // },
        ],
        servicesDetails: [
            {
                Header: 'Services Name',
                accessor: 'servicesName',
                sortable: false,
                headerStyle,
                style,
            },
        ],
        product: [
            {
                Header: 'Principal Name',
                accessor: 'principalName & principalCode',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `${original.principalName}-(${original.principalCode})`,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Promo',
                accessor: 'productGroupDesc',
                sortable: false,
                headerStyle,
                productStyle,
                Cell: ({ original }) => {
                    return <PromoByProductPopup data={original} promo="View Promo" />
                },
            },
            {
                Header: 'Price',
                accessor: 'price && currency',
                headerStyle,
                productStyle,
                sortable: false,
                Cell: ({ original }) => `${original.currency} ${original.price}`,
            },
        ],
        brand: [
            {
                Header: 'Accounting Document Number',
                accessor: 'productGroupDesc',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => <span className="font-bold" style={{ color: '#157478' }}>{original.productGroupDesc}</span>
            },
            {
                Header: 'Sales District',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Sales Office',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Reference Document Number',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Invoice Date',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Invoice Payment Term',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Billing Document',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Greater than 360',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            {
                Header: 'Greater 30',
                accessor: 'totalProduct',
                headerStyle,
                style,
                sortable: false
            },
            // {
            //     Header: 'Action',
            //     accessor: 'action',
            //     headerStyle,
            //     style,
            //     sortable: false,
            //     Cell: ({ original }) => {
            //         return (
            //             <span className="cursor-pointer hover:underline" onClick={() => handleOutlet(original.productGroupDesc)}>View Product</span>
            //         )
            //     }
            // }
        ],
        orderTracking: [
            {
                Header: 'Customer Code',
                accessor: 'customerCode',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Customer Name',
                accessor: 'customerName',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<Link to={`/customer-management/order-tracking-outlet/${original.customerCode}`} className="text-secondary hover:underline">{original.customerName}</Link>),
                width: 150
            },
            {
                Header: 'Transacsation Code',
                accessor: 'transactionNo',
                headerStyle,
                style,
                sortable: false,
                width: 175
            },
            {
                Header: 'SO Number',
                accessor: 'orderNumber',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Invoice No',
                accessor: 'invoiceNumber',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Material Code',
                accessor: 'materialCode',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Order Date',
                accessor: 'orderDate',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => formatDate(original.orderDate, 'dd-MM-yyyy'),
                width: 150
            },
            {
                Header: 'Amount',
                accessor: 'orderValue',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `Rp ${toCurrency(original.orderValue)}`,
                width: 150
            },
            {
                Header: 'Status',
                accessor: 'statusRaw',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.statusHexColor}` }}>{original.statusRaw}</p>),
                width: 150
            },
            {
                Header: 'Status Update Time',
                accessor: 'updatedAt',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => formatDate((original.updatedAt), 'dd-MM-yyyy - hh:ii'),
                width: 175
            },
            {
                Header: 'Order Status',
                accessor: 'orderStatusRaw',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.orderStatusHexColor}` }}>{original.orderStatusRaw}</p>),
                width: 175
            },
        ],
        orderTrackingOutlet: [
            {
                Header: 'Customer Code',
                accessor: 'customerCode',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Transacsation Code',
                accessor: 'transactionNo',
                headerStyle,
                style,
                sortable: false,
                width: 175
            },
            {
                Header: 'SO Number',
                accessor: 'orderNumber',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Invoice No',
                accessor: 'invoiceNumber',
                headerStyle,
                style,
                sortable: false,
                width: 150
            },
            {
                Header: 'Order Date',
                accessor: 'orderDate',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => formatDate(original.orderDate, 'dd-MM-yyyy'),
                width: 150
            },
            {
                Header: 'Amount',
                accessor: 'orderValue',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `Rp ${toCurrency(original.orderValue)}`,
                width: 150
            },
            {
                Header: 'Status',
                accessor: 'statusRaw',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.statusHexColor}` }}>{original.statusRaw}</p>),
                width: 175
            },
            {
                Header: 'Status Update Time',
                accessor: 'updatedAt',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => formatDate((original.updatedAt), 'dd-MM-yyyy - hh:ii'),
                width: 175
            }
        ],
        salesTrendPrincipal: [
            {
                headerStyle,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Principal</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'principal',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 110,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>SubGrup</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'subgrup',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 120,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Group</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>ASM</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>FSS</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Sales Name</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 130,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Actual Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].actualSales}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Target</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'target',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].target}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Pre.Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'previousSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].previousSales}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].previousSales}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        salesTrendChannel: [
            {
                headerStyle,
                columns: [{
                    Header: 'Group',
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: 'ASM',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    // width: 120,
                }, {
                    Header: 'FSS',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: 'Salesman Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Channel (IC4)</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'channel',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Customer ID</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'customerCode',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 130,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Actual Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].actualSales}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Target</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'target',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].target}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Pre.Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'previousSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].previousSales}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].previousSales}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        salesTrendBranch: [
            {
                headerStyle,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Group</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: 'ASM',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    // width: 120,
                }, {
                    Header: 'FSS',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: 'Sales Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Actual Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].actualSales}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Target</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'target',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].target}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Pre.Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'previousSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].previousSales}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].previousSales}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        salesTrendBrand: [
            {
                headerStyle,
                columns: [{
                    Header: 'Group',
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: 'ASM',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    // width: 120,
                }, {
                    Header: 'FSS',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: 'Sales Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Brand</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'productGroupName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: 'Product',
                    accessor: 'productName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Actual Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].actualSales}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Target</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'target',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].target}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => { return (<span>#######</span>) },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Pre.Sales</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'previousSales',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].previousSales}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].previousSales}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        salesTrendOutlet: [
            {
                headerStyle,
                columns: [{
                    Header: 'Channel (IC4)',
                    accessor: 'channelIc4',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: 'ASM',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    // width: 120,
                }, {
                    Header: 'FSS',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: 'Sales Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Customer Trx</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletTrx',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletTrx}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].outletTrx}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Customer Plan</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletPlan',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletPlan}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].outletPlan}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Prev Trx</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletPrevPlan',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletPrevPlan}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].outletPrevPlan}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        salesTrendEzrx: [
            {
                headerStyle,
                columns: [{
                    Header: 'Channel (IC4)',
                    accessor: 'channelIc4',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                    Footer: () => { return (<span>Grand Total</span>) },
                }, {
                    Header: 'ASM',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    // width: 120,
                }, {
                    Header: 'FSS',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                }, {
                    Header: 'Sales Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Principal</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Channel (IC4)</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'channelIc4',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-between">
                                <div className="p-1">
                                    <p>Channel (CG1)</p>
                                </div>
                                <div className="flex justify-center">
                                    <img src={ICFilter} alt="filter" />
                                </div>
                            </div>
                        )
                    },
                    accessor: 'channelCg1',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 155,
                }]
            },
            {
                Header: 'Bulan',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Customer Trx</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletTrx',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletTrx}</span>)
                    },
                    headerStyle,
                    style,
                    width: 130,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].outletTrx}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Customer Plan</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletPlan',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletPlan}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].outletPlan}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Achievement</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'achievement',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].achievement}</span>)
                    },
                    headerStyle,
                    style,
                    width: 150,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].achievement + '%'}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Prev Trx</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletPrevPlan',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].outletPrevPlan}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 : detail.meta[0].outletPrevPlan}</span>
                    },
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>Growth</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'growth',
                    Cell: ({ original }) => {
                        return (<span>{original.trendMonthly[0].growth}</span>)
                    },
                    headerStyle,
                    style,
                    Footer: () => {
                        return <span className="text-center">{Object.keys(detail).length === 0 ? 0 + '%' : detail.meta[0].growth + '%'}</span>
                    },
                }]
            }
        ],
        callReportOutletCoverage: [
            {
                Header: 'Category',
                accessor: 'category',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeCategory}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.category}</p>)
            },
            {
                Header: 'Previous Month',
                accessor: 'previousMonth',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodePreviousMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.isBold ? original.previousMonth + "%" : original.previousMonth === "" ? 0 : original.previousMonth}</p>)
            },
            {
                Header: 'Selected Month',
                accessor: 'selectedMonth',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeSelectedMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.isBold ? original.selectedMonth + "%" : original.selectedMonth === "" ? 0 : original.selectedMonth}</p>)
            },
        ],
        callReportCallRate: [
            {
                Header: 'Category',
                accessor: 'category',
                minWidth: 250,
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeCategory}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.category}</p>)
            },
            {
                Header: 'Previous Month',
                accessor: 'previousMonth',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodePreviousMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.isBold ? original.previousMonth + "%" : original.previousMonth === "" ? 0 : original.previousMonth}</p>)
            },
            {
                Header: 'Selected Month',
                accessor: 'selectedMonth',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeSelectedMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.isBold ? original.selectedMonth + "%" : original.selectedMonth === "" ? 0 : original.selectedMonth}</p>)
            },
        ],
        callReportChannel: [
            {
                Header: 'Category',
                accessor: 'category',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeCategory}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.category}</p>)
            },
            {
                Header: 'Previous Month',
                accessor: 'previousMonth',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodePreviousMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.previousMonth}</p>)
            },
            {
                Header: 'Selected Month',
                accessor: 'selectedMonth',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (<p style={{ color: `${original.hexCodeSelectedMonth}`, fontWeight: `${original.isBold ? 'bold' : 'normal'}` }}>{original.selectedMonth}</p>)
            }
        ],
        monthlySalesReportPrincipal: [
            {
                Header: 'Division',
                accessor: 'division',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Subgrup',
                accessor: 'subGroup',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Group',
                accessor: 'branchName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'ASM',
                accessor: 'asmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'slmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target',
                accessor: 'target',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.salesTarget}</span>
                },
            },
            {
                Header: 'Actual Sales',
                accessor: 'actualSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.currentSales}</span>
                },
            },
            {
                Header: 'Achivement',
                accessor: 'achivement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.achivement}</span>
                },
            },
            {
                Header: 'Previous Sales',
                accessor: 'previousSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.previousSales}</span>
                },
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.growth}</span>
                },
            },
            {
                Header: 'Ams 3',
                accessor: 'ams3',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Growth',
                accessor: 'growthAms3',
                headerStyle,
                style,
                sortable: false,
            },
        ],
        monthlySalesReportOutlet: [
            {
                Header: 'Group',
                accessor: 'branchName',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'ASM',
                accessor: 'asmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'slmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Customer ID',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Channel (IC4)',
                accessor: 'ic4',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Channel (CG1)',
                accessor: 'ic4Description',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target',
                accessor: 'target',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.salesTarget}</span>
                },
            },
            {
                Header: 'Actual Sales',
                accessor: 'actualSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.currentSales}</span>
                },
            },
            {
                Header: 'Achievement',
                accessor: 'achivement',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Previous Sales',
                accessor: 'previousSales',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'EZRX Status',
                accessor: 'ezrxTransactionStatus',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Ams 3',
                accessor: 'ams3',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Growth',
                accessor: 'growthAms3',
                headerStyle,
                style,
                sortable: false,
            },

        ],
        monthlySalesReportProduct: [
            {
                Header: 'Group',
                accessor: 'branchName',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'ASM',
                accessor: 'asmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'slmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Product',
                accessor: 'materialName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'SKU',
                accessor: 'materialCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Brand',
                accessor: 'productGroupDesc',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Tanggal Order',
                accessor: 'invoiceDate',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Customer ID',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outeltName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Actual Sales',
                accessor: 'actualSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.currentSales}</span>
                },
            },
            {
                Header: 'Order Type',
                accessor: 'ezrxTransactionRaw',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'EZRX Status',
                accessor: 'ezrxTransactionStatus',
                headerStyle,
                style,
                sortable: false,
            },
        ],
        monthlySalesReportSalesman: [
            {
                Header: 'Group',
                accessor: 'branchName',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'ASM',
                accessor: 'asmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'slmName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target Sales',
                accessor: 'target',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.targetSales}</span>
                },
            },
            {
                Header: 'Actual Sales',
                accessor: 'actualSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.currentSales}</span>
                },
            },
            {
                Header: 'Achievement',
                accessor: 'achivement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.achivement}</span>
                },
            },
            {
                Header: 'Previous Sales',
                accessor: 'previousSales',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.previousSales}</span>
                },
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.growth}</span>
                },
            },
            {
                Header: 'Customer Trx',
                accessor: 'outletTrx',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.currentSales}</span>
                },
            },
            {
                Header: '%Customer Trx',
                accessor: 'outletTrxPercentage',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>0</span>
                },
            },
            {
                Header: 'EZRX Trx',
                accessor: 'ezrxTransaction',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>0</span>
                },
            },
            {
                Header: '% EZRX Trx',
                accessor: 'ezrxTransactionPercentage',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>0</span>
                },
            },
            {
                Header: 'Ams 3',
                accessor: 'ams3',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.ams3}</span>
                },
            },
            {
                Header: 'Growth',
                accessor: 'growthAms3',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>{Object.keys(detail).length === 0 ? 0 : detail.meta.growthAms3}</span>
                },
            },
        ],
        collectionTrendReportOutlet: [
            {
                headerStyle,
                columns: [
                    {
                        Header: 'Group',
                        accessor: 'branch',
                        headerStyle,
                        Footer: () => { return (<span>Sub Total</span>) },
                    },
                    {
                        Header: 'ASM',
                        accessor: 'asmFullname',
                        headerStyle,
                    },
                    {
                        Header: 'FSS',
                        accessor: 'fssFullname',
                        headerStyle,
                    },
                    {
                        Header: 'Salesman Name',
                        accessor: 'salesmanName',
                        headerStyle,
                    },
                    {
                        Header: 'Customer Code',
                        accessor: 'customerCode',
                        headerStyle,
                    },
                    {
                        Header: 'Customer Name',
                        accessor: 'customerName',
                        headerStyle,
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Januari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Febuari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Maret'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'April'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Mei'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juni'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juli'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Agustus'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'September'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Oktober'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'November'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Desember'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].targetCollection
                        },
                    }
                ]
            },
        ],
        collectionTrendReportChannel: [
            {
                headerStyle,
                columns: [
                    {
                        Header: 'Group',
                        accessor: 'branch',
                        headerStyle,
                        Footer: () => { return (<span>Sub Total</span>) },
                    },
                    {
                        Header: 'ASM',
                        accessor: 'asmFullname',
                        headerStyle,
                    },
                    {
                        Header: 'FSS',
                        accessor: 'fssFullname',
                        headerStyle,
                    },
                    {
                        Header: 'Salesman Name',
                        accessor: 'salesmanName',
                        headerStyle,
                    },
                    {
                        Header: 'Channel (IC4)',
                        accessor: 'industryCode4',
                        headerStyle,
                    },
                    {
                        Header: 'Channel (CG1)',
                        accessor: 'imsChannel4Desc',
                        headerStyle,
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Januari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Febuari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Maret'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'April'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Mei'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juni'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juli'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Agustus'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'September'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Oktober'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'November'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Desember'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].targetCollection
                        },
                    }
                ]
            }
        ],
        collectionTrendReportJKN: [
            {
                headerStyle,
                columns: [
                    {
                        Header: 'Group',
                        accessor: 'branch',
                        headerStyle,
                        Footer: () => { return (<span>Sub Total</span>) },
                    },
                    {
                        Header: 'ASM',
                        accessor: 'asmFullname',
                        headerStyle,
                    },
                    {
                        Header: 'FSS',
                        accessor: 'fssFullname',
                        headerStyle,
                    },
                    {
                        Header: 'Salesman Name',
                        accessor: 'salesmanName',
                        headerStyle,
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Januari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[0].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Febuari'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[1].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Maret'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[2].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'April'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[3].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Mei'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[4].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juni'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Juli'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[5].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Agustus'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[7].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'September'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[8].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Oktober'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[9].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'November'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[10].targetCollection
                        },
                    }
                ]
            },
            {
                Header: ({ data }) => {
                    return 'Desember'
                },
                headerStyle,
                columns: [
                    {
                        Header: 'Achievement',
                        accessor: 'achievement',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].achievement
                        },
                    },
                    {
                        Header: 'ActualCollection',
                        accessor: 'actualCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].actualCollection
                        },
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].date
                        },
                    },
                    {
                        Header: 'Growth',
                        accessor: 'growth',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].growth
                        },
                    },
                    {
                        Header: 'Previous Collection',
                        accessor: 'previousCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].previousCollection
                        },
                    },
                    {
                        Header: 'Target Collection',
                        accessor: 'targetCollection',
                        headerStyle,
                        Cell: ({ row }) => {
                            return row._original.data[11].targetCollection
                        },
                    }
                ]
            },
        ],
        monthlyCollectionReportPrincipal: [
            {
                Header: 'Division',
                accessor: 'divisionName',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'Principal',
                accessor: 'principalCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Subgrup',
                accessor: 'subGroup',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Group',
                accessor: 'branch',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'ASM',
                accessor: 'asmFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target',
                accessor: 'targetCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalTargetCollection}</span>
                }
            },
            {
                Header: 'Actual Collection',
                accessor: 'actualCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalCollection}</span>
                },
            },
            {
                Header: 'Achievement',
                accessor: 'achievement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.achievement}</span>
                }
            },
            {
                Header: 'Previous Collection',
                accessor: 'previousCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalPreviousCollection}</span>
                }
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.growth}</span>
                }
            }
        ],
        monthlyCollectionReportOutlet: [
            {
                Header: 'Group',
                accessor: 'branch',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'ASM',
                accessor: 'asmFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Channel (IC4)',
                accessor: 'industryCode4Name',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Channel (CG1)',
                accessor: 'imsChannel4Desc',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target',
                accessor: 'targetCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalTargetCollection}</span>
                }
            },
            {
                Header: 'Actual Collection',
                accessor: 'actualCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalTargetCollection}</span>
                }
            },
            {
                Header: 'Achievement',
                accessor: 'achievement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.achievement}</span>
                }
            },
            {
                Header: 'Previous Collection',
                accessor: 'previousCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalPreviousCollection}</span>
                }
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.growth}</span>
                }
            },
            {
                Header: 'AR Value',
                accessor: 'arValue',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalAr}</span>
                }
            },
            {
                Header: '% AR Value',
                accessor: 'arValueP',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalArP}</span>
                }
            },
            {
                Header: 'JKN',
                accessor: 'isJkn',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => original.isJkn === false ? 0 : 1,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalJknCollection}</span>
                }
            }
        ],
        monthlyCollectionReportSalesman: [
            {
                Header: 'Group',
                accessor: 'branch',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span>Sub Total</span>
                },
            },
            {
                Header: 'ASM',
                accessor: 'asmFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'FSS',
                accessor: 'fssFullname',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target Collection',
                accessor: 'targetCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalTargetCollection}</span>
                }
            },
            {
                Header: 'Actual Collection',
                accessor: 'actualCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalCollection}</span>
                }
            },
            {
                Header: 'Achievement',
                accessor: 'achievement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.achievement}</span>
                }
            },
            {
                Header: 'Previous Collection',
                accessor: 'previousCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalPreviousCollection}</span>
                }
            },
            {
                Header: 'Growth',
                accessor: 'growth',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.growth}</span>
                }
            },
            {
                Header: 'AR Archievement',
                accessor: 'arAchievement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalArP}</span>
                }
            },
            {
                Header: 'JKN Collection',
                accessor: 'jknCollection',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalJknCollection}</span>
                }
            },
            {
                Header: 'JKN Archievement',
                accessor: 'jknAchievement',
                headerStyle,
                style,
                sortable: false,
                Footer: () => {
                    return <span className='text-center'>{Object.keys(detail).length === 0 ? 0 : detail.meta.totalJknP}</span>
                }
            }
        ],
    }
}

export default useFieldRowOne;