import React, { useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect } from "react";
import { powerBIAPIForDashboardMEnu } from "../../redux/api/api";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const statusEvent = {
    LOADER: 'loaded',
    RENDERED: 'rendered',
    ERROR: 'error'
}

const PowerBIForCallPlan = ({reportId, workspaceId}) => {

    console.log("reportId", reportId, workspaceId)
    ;
    const user = useSelector(state => state.auth.user);
    const userEmail = user ? user.userEmail : null;
    // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState();

    // API end-point url to get embed config for a sample report
    // Report config useState hook
    // Values for properties like embedUrl, embedToken and settings will be set on click of buttons below
    const [reportConfig, setReportConfig] = useState({});
    const [embedConfig, setEmbedConfig] = useState({
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        embedUrl: undefined,
        id: undefined,
        permissions: models.Permissions.All,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: true
                }
            },
            background: models.BackgroundType.Transparent,
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToPage
            }
        },
    });

    // Get info report config
    const isMounted = React.useRef(false);
    const getReportConfig = async () => {
        if (!isMounted.current) return
        await powerBIAPIForDashboardMEnu.get(reportId, workspaceId)
            .then(res => {
                if (!res.data.isSuccess) return;
                setReportConfig(res.data.responseData)
            }).catch(err => console.error(err));
    }

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        [statusEvent.LOADER, () => console.log("Report has loaded")],
        [statusEvent.RENDERED, () => console.log("Report has rendered")],
        [statusEvent.ERROR, (event) => {
            if (!event) return
            const detail = event.detail;
            toast.error(detail.detailedMessage)
            // Handle refresh token when expiration
            if ([401].includes(detail.errorCode)) {
                getReportConfig();
            }
        }]
    ]);

    useEffect(() => {
        isMounted.current = true;
        getReportConfig();
        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        setEmbedConfig(prevState => {
            return {
                ...prevState,
                id: reportConfig.reportId,
                embedUrl: reportConfig.embedUrl,
                accessToken: reportConfig.embedToken,
            }
        })

    }, [reportConfig])

    useEffect(() => {
        if (!report) return

        const filter = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "User Access",
                column: "member_email"
            },
            operator: "In",
            values: [userEmail]
        };

        const updateFilter = async (filter) => await report.updateFilters(models.FiltersOperations.ReplaceAll, [filter])

        report.on('loaded', event => {
            updateFilter(filter)
        })
    }, [report, userEmail]);

    return (
        <Paper style={{ height: '150vh', width: '100%' }}>
            <PowerBIEmbed
                embedConfig={embedConfig}
                eventHandlers={eventHandlersMap}
                cssClassName={"h-full"}
                getEmbeddedComponent={(embedObject) => {
                    setReport(embedObject);
                }}
            />
        </Paper>
    );
}

export default PowerBIForCallPlan;